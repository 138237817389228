/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useMemo } from 'react';

import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { UiContext } from '../../context/UiContext';
import { signUp } from '../../helpers/globalAuth';
import SplitTemplate from '../../components/SplitTemplate';
import UnderlinedInput from '../../components/Inputs/UnderlinedInput';
import ConfirmButton from '../../components/Buttons/ConfirmButton';
import classes from './styles.module.scss';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .required('This field is required')
    .matches(/^\S+$/, 'This field cannot contain white space'),
  lastName: yup
    .string()
    .trim()
    .required('This field is required')
    .matches(/^\S+$/, 'This field cannot contain white space'),
  title: yup.string().trim().required('This field is required'),
  companyName: yup.string().trim(),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('This field is required'),
  password: yup
    .string()
    .required('This field is required')
    .min(8, 'The password should have at minimum length of 8'),
  confirmPassword: yup
    .string()
    .required('This field is required')
    .oneOf([yup.ref('password'), null], 'Passwords do not match'),
});

export default function SignUpPage() {
  const navigate = useNavigate();
  const { notifyError, redirectAfterLogin, setRedirectAfterLogin } =
    useContext(UiContext);
  const isMobile = useCheckMobileScreen();

  useEffect(
    () => setRedirectAfterLogin(isMobile ? '/my-videos' : '/my-library'),
    [isMobile, setRedirectAfterLogin, redirectAfterLogin]
  );

  const createUser = async (values) => {
    try {
      const { firstName, lastName, email, password, companyName, title } =
        values;
      const fullName = `${firstName} ${lastName}`;

      await signUp(email, password, fullName, companyName, title);

      navigate(redirectAfterLogin || '/');

      if (redirectAfterLogin) {
        setRedirectAfterLogin('');
      }
    } catch (error) {
      console.log(error);
      notifyError(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      title: '',
      companyName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: createUser,
  });

  const hasError = useMemo(
    () => Object.values(formik.errors).some((error) => error),
    [formik.errors]
  );
  const hasEmptyValue = useMemo(
    () => Object.values(formik.values).some((value) => !value.length),
    [formik.values]
  );

  const isSubmitDisabled = hasError || hasEmptyValue;

  return (
    <div className={classes.SignUpPage}>
      <SplitTemplate>
        <span className={classes.signIn}>
          <Link to="/sign-in">Sign in</Link>
        </span>
        <h1>Sign up</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.row}>
            <UnderlinedInput
              label={
                formik.errors.firstName && formik.touched.firstName
                  ? formik.errors.firstName
                  : 'First name'
              }
              value={formik.values.firstName}
              errors={formik.errors.firstName}
              isTouched={formik.touched.firstName}
              type="text"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <UnderlinedInput
              label={
                formik.errors.lastName && formik.touched.lastName
                  ? formik.errors.lastName
                  : 'Last name'
              }
              value={formik.values.lastName}
              errors={formik.errors.lastName}
              isTouched={formik.touched.lastName}
              type="text"
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <UnderlinedInput
            label={
              formik.errors.title && formik.touched.title
                ? formik.errors.title
                : 'Your title'
            }
            value={formik.values.title}
            errors={formik.errors.title}
            isTouched={formik.touched.title}
            type="text"
            name="title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <UnderlinedInput
            label={
              formik.errors.companyName && formik.touched.companyName
                ? formik.errors.companyName
                : 'Company name'
            }
            value={formik.values.companyName}
            type="text"
            name="companyName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <UnderlinedInput
            label={
              formik.errors.email && formik.touched.email
                ? formik.errors.email
                : 'Your email'
            }
            value={formik.values.email}
            errors={formik.errors.email}
            isTouched={formik.touched.email}
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <UnderlinedInput
            label={
              formik.errors.password && formik.touched.password
                ? formik.errors.password
                : 'Your password'
            }
            value={formik.values.password}
            errors={formik.errors.password}
            isTouched={formik.touched.password}
            type="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isPassword
            doPasswordsMatch={
              formik.errors.confirmPassword !== 'Passwords do not match'
            }
          />

          <div className={classes.row}>
            <UnderlinedInput
              label={
                formik.errors.confirmPassword === 'This field is required' &&
                formik.touched.confirmPassword
                  ? 'This field is required'
                  : 'Confirm password'
              }
              value={formik.values.confirmPassword}
              errors={
                formik.errors.confirmPassword === 'This field is required'
              }
              isTouched={formik.touched.confirmPassword}
              type="password"
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ConfirmButton isDisabled={isSubmitDisabled} />
          </div>
        </form>
        <p className={classes.termsPrivacyMessage}>
          By moving to the next step you accept our{' '}
          <a href="/" target="_blank">
            Terms & Conditions
          </a>{' '}
          and{' '}
          <a href="/" target="_blank">
            Privacy Policy
          </a>
        </p>
      </SplitTemplate>
    </div>
  );
}
