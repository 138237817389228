import React from 'react';

import moment from 'moment';

import InfoButton from '../InfoButton';

import classes from './styles.module.scss';

export default function MeetingRow({
  row,
  nestLevel,
  showSummaryReportModal,
  isExport,
}) {
  return (
    <tr className={classes.MeetingRow}>
      <td>
        <div
          style={{
            paddingLeft: `${(nestLevel - 1) * 40}px`,
          }}
        >
          {row.title}
        </div>
      </td>
      <td />
      <td>
        {row.viewedCount} out of {row.totalUsers}
      </td>
      <td>{Math.round((row.viewedCount / row.totalUsers) * 100)}%</td>
      <td>{moment(row.createdAt).format('MMM DD, YYYY')}</td>
      {!isExport && (
        <td>
          <InfoButton
            onClick={() => showSummaryReportModal(row.id, 'meeting', row.title)}
          />
        </td>
      )}
    </tr>
  );
}
