/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';

import UserSearchSuggestion from './UserSearchSuggestion';

import classes from './styles.module.scss';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

export default function RoundedInput({
  value,
  label,
  errors,
  isTouched,
  users,
  readOnly,
  ...props
}) {
  const [isUserSearchSuggestionVisible, setIsUserSearchSuggestionVisible] =
    useState(true);

  const roundedInputRef = useRef();
  useOnClickOutside(roundedInputRef, () => {
    setIsUserSearchSuggestionVisible(false);
  });

  return (
    <div
      className={classNames(classes.RoundedInput, {
        [classes.readOnly]: readOnly,
      })}
      ref={roundedInputRef}
    >
      <input
        onFocus={(event) => {
          if (readOnly) {
            event.target.blur();
          }

          setIsUserSearchSuggestionVisible(true);
        }}
        // onBlur={() => {
        //   setTimeout(() => {
        //     setIsUserSearchSuggestionVisible(false);
        //   }, 200);
        // }}
        className={classNames(
          value && classes.active,
          errors && isTouched && classes.error
        )}
        value={value}
        {...props}
        readOnly={readOnly}
      />
      <label>{label}</label>
      {isUserSearchSuggestionVisible && users && (
        <UserSearchSuggestion
          value={value}
          setValue={props.setValue}
          setIsUserSearchSuggestionVisible={setIsUserSearchSuggestionVisible}
        />
      )}
    </div>
  );
}
