import React from 'react';

import FolderRow from './FolderRow';
import MeetingRow from './MeetingRow';

export const renderRows = (
  tree,
  nestLevel = 0,
  showSummaryReportModal,
  isExport
) => {
  if (!Array.isArray(tree)) {
    return null;
  }

  return tree.map((node) => {
    if (Array.isArray(node)) {
      return [
        ...renderRows(node, nestLevel + 1, showSummaryReportModal, isExport),
      ];
    }

    if (node.parentId !== undefined) {
      return (
        <FolderRow
          key={node.id}
          row={node}
          nestLevel={nestLevel}
          showSummaryReportModal={showSummaryReportModal}
          isExport={isExport}
        />
      );
    }

    return (
      <MeetingRow
        key={node.id}
        row={node}
        nestLevel={nestLevel}
        showSummaryReportModal={showSummaryReportModal}
        isExport={isExport}
      />
    );
  });
};

/* const sortRowsByName = (rows, sortOrder) => {};

export const sortRows = (rows, sortField, sortOrder) => {};
 */
