import React from 'react';

import classes from './styles.module.scss';

import Th from '../Th';

export default function ViewReportTh({
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  field,
  title,
  isExport,
}) {
  return (
    <Th
      classes={classes}
      sortField={isExport ? '' : sortField}
      sortOrder={sortOrder}
      setSortField={setSortField}
      setSortOrder={setSortOrder}
      field={field}
      title={title}
    />
  );
}
