import React from 'react';

import Modal from 'react-bootstrap/Modal';

import SummaryReport from '../../components/Reports/SummaryReport';

import classes from './styles.module.scss';

export default function SummaryViewerReportModal({
  show,
  id,
  contentTitle,
  handleClose,
  contentType,
}) {
  return (
    <Modal
      onHide={handleClose}
      show={show}
      centered
      className={classes.SummaryViewerReportModal}
      contentClassName={classes.contentContainer}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <SummaryReport
        contentType={contentType}
        contentTitle={contentTitle}
        id={id}
        show={show}
      />
    </Modal>
  );
}
