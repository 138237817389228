/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

import videoIcon from '../../../assets/images/my-library/video.svg';
import playIcon from '../../../assets/images/my-meetings/play.svg';

import downloadVideo from '../../../helpers/downloadVideo';
import ActionsMenu from '../ActionsMenu';
import formatDuration from '../../../helpers/formatDuration';
import useMeetingProcessing from '../../../hooks/useMeetingProcessing';
import Progress from './Progress';
import { ORG_ROLES, USER_ROLES } from '../../../constants/main';

const MeetingRow = ({
  classes,
  meeting,
  user,
  folderUserRole,
  handleDetails,
  handleDelete,
  handleMoveToTrash,
  handleRestore,
  handleCopyLink,
  refetchFolder,
  showReport,
  isTrashBin,
}) => {
  const [processingProgress, setProcessingProgress] = useState(null);

  const processing = useMeetingProcessing();

  const navigate = useNavigate();

  const isOrganizationOwner = user?.orgRole?.access === ORG_ROLES.OWNER;

  const meetingUserRole = meeting.MeetingUsers?.[0]?.userRole;
  const isMeetingPublished = meeting.status === 'published';

  const isNotAllowedToView = !isMeetingPublished;

  const isNotAllowedToDelete =
    meeting.status === 'in progress' ||
    (folderUserRole !== 'creator' &&
      folderUserRole !== 'owner' /* &&
      meeting.userId !== user?.id */ &&
      !isOrganizationOwner);

  const isNotAllowedToDownload =
    !isMeetingPublished || user?.role !== USER_ROLES.SUPER_ADMIN;

  const isNotAllowedToEdit = isOrganizationOwner
    ? false
    : meetingUserRole === 'viewer' || meeting.status === 'in progress';

  const isNotAllowedToShare =
    (folderUserRole !== 'creator' && folderUserRole !== 'owner') /* &&
      meeting.userId !== user?.id */ ||
    !isMeetingPublished;

  const isNotAllowedToCopyLink =
    (folderUserRole !== 'creator' &&
      folderUserRole !== 'owner' &&
      meeting.userId !== user?.id) ||
    !isMeetingPublished;

  const isNotAllowedToGetViewerReport =
    (!isMeetingPublished || meetingUserRole === 'viewer') &&
    !isOrganizationOwner;

  const duration = formatDuration(meeting.duration);

  const lastPublishedDate = moment(meeting.updatedAt).format('MMM D, YYYY');

  // Refech folder when video processing is finished
  useEffect(() => {
    if (processing.step === 'finishing') {
      refetchFolder();
    }
  }, [processing, refetchFolder]);

  // Set processing progress, filter by meeting id
  useEffect(() => {
    if (processing.meetingId === meeting.id) {
      setProcessingProgress(processing);
    }
  }, [meeting.id, processing]);

  return (
    <tr key={meeting.id}>
      <td className={classes.title}>
        <div className={classes.innerContainer}>
          <img src={videoIcon} alt="Video" />
          <div>
            <span>{meeting.title}</span>
            {!processingProgress || isMeetingPublished ? (
              <span className={classes.publishedDate}>
                modified {lastPublishedDate}
              </span>
            ) : (
              <Progress
                isVisible={processingProgress}
                percentage={processingProgress?.percentage}
                step={processingProgress?.step}
              />
            )}
          </div>
        </div>
        <button
          className={classes.playButton}
          disabled={isNotAllowedToView}
          type="button"
          onClick={() => navigate(`/my-videos/${meeting.id}`)}
        >
          <img src={playIcon} alt="Play" />
        </button>
      </td>
      <td className={classes.owner}>
        {meeting.userId === user?.id ? 'Me' : meeting.Owner.name}
      </td>
      <td className={classes.publishedDate}>
        {processingProgress && processingProgress.step !== 'finishing'
          ? 'Processing'
          : lastPublishedDate}
      </td>
      <td className={classes.buttons}>
        <ActionsMenu
          data={meeting}
          handleDetails={handleDetails}
          handleDelete={handleDelete}
          handleMoveToTrash={handleMoveToTrash}
          handleRestore={handleRestore}
          handleEdit={() => navigate(`/my-videos/${meeting.id}/edit`)}
          handleShare={() =>
            navigate(`/my-videos/${meeting.id}/edit?share=true`)
          }
          handleDownload={() => downloadVideo(meeting)}
          // handleGetViewerReport={() =>
          //   window.open(`/viewer-report/${meeting.id}`, '_blank')
          // }
          handleGetViewerReport={() => showReport(meeting)}
          handleCopyLink={handleCopyLink}
          isNotAllowedToDelete={isNotAllowedToDelete}
          isNotAllowedToEdit={isNotAllowedToEdit}
          isNotAllowedToShare={isNotAllowedToShare}
          isNotAllowedToDownload={isNotAllowedToDownload}
          isNotAllowedToCheckDetails={false}
          isNotAllowedToGetViewerReport={isNotAllowedToGetViewerReport}
          isNotAllowedToCopyLink={isNotAllowedToCopyLink}
          isTrashBin={isTrashBin}
        />
        <span className={classes.duration}>{meeting.duration && duration}</span>
        {isNotAllowedToView ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                This video is not yet published. To preview, click on the three
                dots to the left and select EDIT and then PREVIEW.
              </Tooltip>
            }
          >
            <button
              disabled={isNotAllowedToView}
              type="button"
              onClick={() => navigate(`/my-videos/${meeting.id}`)}
            >
              <img src={playIcon} alt="Play" />
            </button>
          </OverlayTrigger>
        ) : (
          <button
            className={classes.playButton}
            disabled={isNotAllowedToView}
            type="button"
            onClick={() => navigate(`/my-videos/${meeting.id}`)}
          >
            <img src={playIcon} alt="Play" />
          </button>
        )}
      </td>
    </tr>
  );
};

export default MeetingRow;
