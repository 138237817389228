import React from 'react';

import classes from './styles.module.scss';

const Progress = ({ isVisible, percentage, step }) => {
  // console.log('Progress', isVisible, percentage, step);

  return (
    <div
      className={classes.Progress}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
    >
      <div
        className={classes.bar}
        style={{
          width: `${percentage || 0}%`,
        }}
      />
      <span className={classes.step}>{step}</span>
    </div>
  );
};

export default Progress;
