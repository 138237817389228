/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import closeIcon from '../../assets/images/closeIcon.svg';
import RoundedInput from '../../components/Inputs/RoundedInput';
import FilledButton from '../../components/Buttons/FilledButton';
import EmojiPickerWithButton from '../../components/EmojiPickerWithButton';
import Textarea from '../../components/Textarea';
import classes from './styles.module.scss';
import validateEmail from '../../helpers/validateEmail';
import InfoIcon from '../../components/InfoIcon';
import Option from '../../components/Option';
import FolderService from '../../services/FolderService';
import { UiContext } from '../../context/UiContext';

const USER_ROLE = {
  VIEWER: 'viewer',
  CONTRIBUTOR: 'contributor',
};

export default function ShareModal({
  show,
  handleClose,
  data,
  type,
  setIsShareSuccessPopupVisible,
}) {
  const [emails, setEmails] = useState('');
  const [message, setMessage] = useState('');
  const [userRole, setUserRole] = useState(USER_ROLE.VIEWER);
  const [emailsValidationError, setEmailsValidationError] = useState('');

  const { showShareMeetingSuccessModal, notifyError } = useContext(UiContext);

  const [searchParams] = useSearchParams();

  const email = searchParams.get('email');
  const meetingId = searchParams.get('meetingId');

  const isFolder = type === 'folder' || type === 'subfolder';

  useEffect(() => {
    setTimeout(() => {
      if (email) {
        setEmails(email);
      }
    }, 0);
  }, [email]);

  const handleSubmit = async () => {
    let isValid = true;
    setEmailsValidationError(null);

    let emailsList = [];

    if (!emails) {
      setEmailsValidationError('This field is required');
      isValid = false;
    } else {
      emailsList = emails.split(';').join(',').split(',');
      emailsList = emailsList.map((mail) => mail.trim());

      const isEveryEmailValid = emailsList.every((mail) => validateEmail(mail));

      if (!isEveryEmailValid) {
        setEmailsValidationError('Emails must be valid');
        isValid = false;
      }
    }

    if (isValid) {
      try {
        if (isFolder) {
          await FolderService.shareFolder({
            folderId: data.id,
            emails: emailsList,
            message,
            userRole,
            meetingId,
          });
        } else if (type === 'meeting') {
          await axios.post(`/meetings/${data.id}/share`, {
            emails: emailsList,
            message,
            userRole,
          });

          setIsShareSuccessPopupVisible(true);
          showShareMeetingSuccessModal();
        }

        handleClose();
      } catch (error) {
        notifyError(error?.response?.data?.message);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!show) {
      setEmailsValidationError(null);
      setMessage('');
      setEmails('');
    }
  }, [show]);

  const name = isFolder ? data?.name : data?.title;

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.ShareModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <header>
        <h1>Share {name}</h1>
      </header>
      <div className={classes.form}>
        <div className={classNames(classes.row, classes.inviteRow)}>
          <div
            className={classNames(classes.inviteUsersContainer, classes.col)}
          >
            <div className={classes.title}>
              <h2>
                Invite others
                <div className={classes.infoIconContainer}>
                  <InfoIcon
                    text={`Use a semicolon ";" after each email address when adding
                    multiple emails`}
                    width={285}
                  />
                </div>
              </h2>
              <hr />
            </div>
            <RoundedInput
              type="text"
              placeholder="Add email addresses"
              value={emails}
              onChange={(event) => setEmails(event.target.value)}
              setValue={setEmails}
              users
            />
            {emailsValidationError && (
              <div className={classes.error}>{emailsValidationError}</div>
            )}
          </div>
          {isFolder && (
            <div
              className={classNames(classes.roleOptionsContainer, classes.col)}
            >
              <div className={classes.title}>
                <h2>Select Role</h2>
                <hr />
              </div>
              <Option
                isChecked={userRole === USER_ROLE.VIEWER}
                onClick={() => setUserRole(USER_ROLE.VIEWER)}
              >
                Viewer
                <InfoIcon
                  text="Can only view files or folders that they are assigned to"
                  width={350}
                />
              </Option>

              <Option
                isChecked={userRole === USER_ROLE.CONTRIBUTOR}
                onClick={() => setUserRole(USER_ROLE.CONTRIBUTOR)}
              >
                Contributor
                <InfoIcon
                  text="Can view, create, and publish videos and add subfolders in this folder"
                  width={350}
                />
              </Option>
            </div>
          )}
        </div>

        <div className={classes.row}>
          <div className={classes.col}>
            <div className={classes.title}>
              <h2>Message</h2>
              <hr />
            </div>
            <div className={classes.messageContainer}>
              <Textarea
                placeholder="Start typing..."
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
              <EmojiPickerWithButton
                setCommentText={setMessage}
                buttonBottom="24px"
                buttonRight="24px"
                pickerTop="52px"
              />
            </div>
          </div>
        </div>
      </div>
      <footer>
        {(type === 'meeting' || type === 'subfolder') && (
          <p>
            <b>IMPORTANT: </b>When sharing subfolders or videos within a parent
            folder, user(s)
            <br /> automatically inherit permissions{' '}
            <u>to all files and folders</u> within the Parent.
          </p>
        )}
        <FilledButton onClick={handleSubmit}>Send</FilledButton>
      </footer>
    </Modal>
  );
}
