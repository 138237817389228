export default class UndoStack {
  constructor() {
    this.stack = [];

    this.undo = () => {
      const last = this.stack.pop();
      if (last) {
        last();
      } else {
        console.log('Nothing to undo');
      }
    };

    this.push = (callback) => {
      this.stack.push(callback);
    };
  }
}
