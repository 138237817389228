import React from 'react';

import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';
import classes from './styles.module.scss';

export default function FileUploadProgressModal({
  show,
  handleClose,
  uploadProgress,
}) {
  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.FileUploadProgressModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.content}>
        <h3>Uploading video</h3>
        <p>keep tab open until complete</p>
        <div className={classes.progressContainer}>
          <progress id="file" max="100" value={uploadProgress}>
            {uploadProgress}%
          </progress>
          <span className={classes.percentage}>{uploadProgress}%</span>
        </div>
      </div>
    </Modal>
  );
}
