import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import Breadcrumbs from '../../components/Breadcrumbs';
import MeetingsList from '../../components/MeetingsList';
import MeetingHistoryList from '../../components/MeetingHistoryList';
import Subfolder from '../../components/Library/Subfolder';

import MeetingService from '../../services/MeetingService';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { MEETING_TABS } from '../../constants/main';
import classes from './styles.module.scss';
import folderIcon from '../../assets/images/my-library/folder.svg';

const MEETING_TABS_LABELS = {
  /* addition to url to get info from tab */
  [MEETING_TABS.MY_VIDEOS]: 'My Videos',
  [MEETING_TABS.MOBILE_UPLOADS]: 'Mobile Uploads',
  [MEETING_TABS.SAVED]: 'My Saved Sections',
};

const subfolders = [
  /* removes the tab */
  /* { tab: MEETING_TABS.MY_VIDEOS, title: 'My Videos' }, */
  { tab: MEETING_TABS.MOBILE_UPLOADS, title: 'My Mobile Uploads' },
  { tab: MEETING_TABS.SAVED, title: 'My Saved Sections' },
];

export default function MeetingsPage() {
  const [searchInput, setSearchInput] = useState('');
  const [selectedTab, setSelectedTab] = useState('my videos');
  const [breadcrumbsOptions, setBreadcrumbsOptions] = useState([
    { label: 'My Videos', to: '/my-videos' },
  ]);
  const [title, setTitle] = useState('');

  const { id: meetingId } = useParams();
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const destinationMeetingId = queryParams.get('meetingId');

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  const { data: meeting } = useQuery(
    ['meeting', meetingId],
    () => MeetingService.getMeeting(meetingId),
    {
      enabled: !!meetingId,
    }
  );

  const handleChangeTab = useCallback(
    (tab) => {
      navigate(`/my-videos?tab=${tab}`);
    },
    [navigate]
  );

  const isHistoryPage = location.pathname.endsWith('/history');

  useEffect(() => {
    const tab = queryParams.get('tab');

    const defaultOptions = [{ label: 'My Videos', to: '/my-videos' }];
    const tempOptions = [...defaultOptions];

    if (tab) {
      setSelectedTab(tab);
      setTitle(MEETING_TABS_LABELS[tab]);
      tempOptions.push({
        label: MEETING_TABS_LABELS[tab],
        to: `/my-videos?tab=${tab}`,
      });
    } else if (isHistoryPage && meeting) {
      setSelectedTab(MEETING_TABS.MY_VIDEOS);
      tempOptions.push({
        label: MEETING_TABS_LABELS[MEETING_TABS.MY_VIDEOS],
        to: `/my-videos?tab=${MEETING_TABS.MY_VIDEOS}`,
      });
      setTitle(meeting.title);
    } else {
      setSelectedTab('my videos');
      setTitle('');
    }

    setBreadcrumbsOptions(tempOptions);
  }, [location, meeting, isHistoryPage]);

  // console.log('meetingId', meetingId, selectedTab);

  let content = (
    <MeetingsList
      classes={classes}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      tab={selectedTab}
      destinationMeetingId={destinationMeetingId}
    />
  );

  if ((meetingId && selectedTab !== 'my videos') || isHistoryPage) {
    content = <MeetingHistoryList classes={classes} meetingId={meetingId} />;
  }

  return (
    <div className={classes.MeetingsPage}>
      <Header
        hasUserBlock
        hasSearch={isMobile}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
      <Navigation />
      <div className={classes.container}>
        <div className={classes.breadcrumbsContainer}>
          <Breadcrumbs
            breadcrumbsOptions={breadcrumbsOptions}
            showBackButton={!!destinationMeetingId}
          />
          <h1 className={classes.title}>{title}</h1>
        </div>
        {selectedTab === 'my videos' && !isHistoryPage && (
          <div className={classes.foldersContainer}>
            {subfolders.map((subfolder) => (
              <Subfolder
                key={subfolder.tab}
                onClick={() => handleChangeTab(subfolder.tab)}
                title={subfolder.title}
                icon={folderIcon}
              />
            ))}
          </div>
        )}
        {content}
      </div>
    </div>
  );
}
