export const sortReport = (users, sortField, sortOrder) => {
  if (!users) {
    return [];
  }

  return users.sort((a, b) => {
    if (sortOrder === 'ASC') {
      return a[sortField] > b[sortField] ? 1 : -1;
    }
    return a[sortField] < b[sortField] ? 1 : -1;
  });
};
