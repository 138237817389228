import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { UiContext } from '../../../context/UiContext';
import SplitTemplate from '../../SplitTemplate';
import UnderlinedInput from '../../Inputs/UnderlinedInput';
import classes from './styles.module.scss';
import { SocketContext } from '../../../context';
import { login } from '../../../helpers/globalAuth';
import FilledButton from '../../Buttons/FilledButton';

export const signInValidationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('This field is required'),
  password: yup
    .string()
    .required('This field is required')
    .min(8, 'The password should have at minimum length of 8'),
});

export default function SignIn({ handleClose }) {
  const { notifyError } = useContext(UiContext);
  const { socket } = useContext(SocketContext);

  const signIn = async (values) => {
    try {
      await login(values.email, values.password, socket);

      handleClose();
    } catch (error) {
      console.log(error);
      notifyError(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    signInValidationSchema,
    onSubmit: signIn,
  });

  const hasError = useMemo(
    () => Object.values(formik.errors).some((error) => error),
    [formik.errors]
  );
  const hasEmptyValue = useMemo(
    () => Object.values(formik.values).some((value) => !value.length),
    [formik.values]
  );

  const isSubmitDisabled = hasError || hasEmptyValue;

  return (
    <div className={classes.SignIn}>
      <SplitTemplate>
        <span className={classes.signUp}>
          <Link to="/sign-up">Sign up</Link>
        </span>
        <h1>Oops, to interact in this video we need a little more info</h1>
        <h2>This lets users see your valuable interactions</h2>
        <form onSubmit={formik.handleSubmit}>
          <UnderlinedInput
            label={
              formik.errors.email && formik.touched.email
                ? formik.errors.email
                : 'Your email'
            }
            value={formik.values.email}
            errors={formik.errors.email}
            isTouched={formik.touched.email}
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <UnderlinedInput
            label={
              formik.errors.password && formik.touched.password
                ? formik.errors.password
                : 'Your password'
            }
            value={formik.values.password}
            errors={formik.errors.password}
            isTouched={formik.touched.password}
            type="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <div className={classes.row}>
            <span className={classes.forgotPassword}>
              Forgot your password? <br />
              <Link to="/forgot-password">Click here</Link> to recover it
            </span>
            <FilledButton type="submit" isDisabled={isSubmitDisabled}>
              Submit
            </FilledButton>
          </div>
        </form>
        <span className={classes.close} onClick={handleClose}>
          I don&apos;t want to interact
        </span>
      </SplitTemplate>
    </div>
  );
}
