import React from 'react';

import { useLocation } from 'react-router-dom';

import SummaryReport from '../../components/Reports/SummaryReport';

import classes from './styles.module.scss';

export default function SummaryReportPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const contentType = queryParams.get('type');

  return (
    <div className={classes.SummaryReportPage}>
      <SummaryReport contentType={contentType} id={id} show isExport />
    </div>
  );
}
