import { useState } from 'react';

import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const rowHeight = 70;
const restHeight = 112 + 108 + 24 + 64;
const mobileItemsRange = Math.floor(
  (window.innerHeight - restHeight) / rowHeight
);

export default function useFetchingState() {
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [sortField, setSortField] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');

  const [debouncedSearchInput] = useDebounce(searchInput, 500);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isShare = queryParams.get('share');
  const meetingId = queryParams.get('meetingId');
  const { id } = useParams();

  const isMobile = useCheckMobileScreen();
  const itemsRange = isMobile ? mobileItemsRange : 5;

  return {
    page,
    setPage,
    searchInput,
    setSearchInput,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    debouncedSearchInput,
    navigate,
    location,
    isShare,
    id,
    isMobile,
    itemsRange,
    meetingId,
  };
}
