import React, { useState, useEffect } from 'react';
import { useMatch } from 'react-router-dom';

import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Modal from 'react-bootstrap/Modal';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import FontSize from './FontSize';

import checkmarkIcon from '../../assets/images/view-meeting/checkmark.svg';
import undoIcon from '../../assets/images/text-editor/undo.svg';
import redoIcon from '../../assets/images/text-editor/redo.svg';
import boldIcon from '../../assets/images/text-editor/bold.svg';
import italicIcon from '../../assets/images/text-editor/italic.svg';
import leftIcon from '../../assets/images/text-editor/left.svg';
import centerIcon from '../../assets/images/text-editor/justify.svg';
import unorderedIcon from '../../assets/images/text-editor/unordered.svg';
import orderedIcon from '../../assets/images/text-editor/ordered.svg';
import linkIcon from '../../assets/images/text-editor/link.svg';

import './styles.scss';
import classes from './styles.module.scss';

const htmlToDraftBlocks = (html) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);

  return editorState;
};

const toolbar = {
  options: ['history', 'fontSize', 'inline', 'textAlign', 'list', 'link'],
  fontSize: { component: FontSize },
  inline: {
    options: ['bold', 'italic'],
    bold: { icon: boldIcon, className: classes.toolbarIcon },
    italic: { icon: italicIcon, className: classes.toolbarIcon },
  },
  list: {
    inDropdown: true,
    options: ['unordered', 'ordered'],
    unordered: { icon: unorderedIcon, className: classes.toolbarIcon },
    ordered: { icon: orderedIcon, className: classes.toolbarIcon },
  },
  textAlign: {
    inDropdown: true,
    options: ['left', 'center'],
    left: { icon: leftIcon, className: classes.toolbarIcon },
    center: { icon: centerIcon, className: classes.toolbarIcon },
  },
  history: {
    undo: { icon: undoIcon, className: classes.toolbarIcon },
    redo: { icon: redoIcon, className: classes.toolbarIcon },
  },
  link: {
    options: ['link'],
    defaultTargetOption: '_blank',
    link: {
      icon: linkIcon,
      className: classes.toolbarIcon,
    },
  },
};

export default function MeetingNotesModal({
  show,
  handleClose,
  noteSectionCount = 0,
  noteSectionData,
  createSection,
  updateTextSection,
}) {
  const [editorState, setEditorState] = useState(null);

  const match = useMatch('/my-videos/:id/edit');

  const changeEditorState = (state) => {
    setEditorState(state);
  };

  const saveMeetingNotes = async () => {
    if (!match?.params?.id) {
      return;
    }

    try {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const markup = draftToHtml(rawContentState);

      if (noteSectionData) {
        updateTextSection({
          sectionId: noteSectionData.id,
          note: {
            text: markup,
          },
        });
      } else {
        createSection({
          meetingId: match?.params?.id,
          title: `Text ${noteSectionCount + 1}`,
          text: markup,
        });
      }

      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (noteSectionData) {
      setEditorState(htmlToDraftBlocks(noteSectionData.text));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [match?.params?.id, show, noteSectionData]);

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.MeetingNotesModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <header className={classes.header}>
        <i className={classes.closeIcon} onClick={handleClose}>
          Close
        </i>
        <h1>Add Text</h1>
      </header>
      <div className={classes.editorContainer}>
        <Editor
          editorState={editorState}
          wrapperClassName={classes.editorWrapper}
          editorClassName={classes.editor}
          onEditorStateChange={changeEditorState}
          toolbar={toolbar}
        />
      </div>
      <footer className={classes.footer}>
        <div className={classes.buttons}>
          <button
            className={classes.saveButton}
            type="button"
            onClick={saveMeetingNotes}
          >
            <img src={checkmarkIcon} alt="Save" />
          </button>
        </div>
      </footer>
    </Modal>
  );
}
