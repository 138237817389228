import React from 'react';

import Tab from './Tab';

import classes from './styles.module.scss';

export default function ReportTabs({ tabs, currentTab, setCurrentTab }) {
  return (
    <div className={classes.ReportTabs}>
      {tabs.map((tab, index) => (
        <Tab
          key={tab}
          tab={tab}
          index={index}
          tabCount={tabs.length}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      ))}
    </div>
  );
}
