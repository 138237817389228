/* eslint-disable new-cap */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import ReportHeader from '../../components/Reports/ReportHeader';
import ReportTabs from '../../components/Reports/ReportTabs';
import FolderStats from '../../components/Reports/FolderStats';
import UsersTable from '../../components/Reports/UsersTable';
import TabContentTitle from '../../components/Reports/TabContentTitle';
import VideosReport from '../../components/Reports/VideosReport';
import SummaryViewerReportModal from '../../modals/SummaryViewerReportModal';

import classes from './styles.module.scss';
import ReportService from '../../services/ReportService';
import { exportReportToPdf, exportTableToExcel } from './helpers';

const tabs = ['Overview', 'Videos', 'Viewers'];

export default function ViewerReportPage() {
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [isSummaryModalVisible, setIsSummaryModalVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentContentType, setCurrentContentType] = useState(null);
  const [currentContentTitle, setCurrentContentTitle] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  const { id } = useParams();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isExport = query.get('export');

  const { data: folderStats } = useQuery({
    queryKey: ['folderStats', id],
    queryFn: () => ReportService.getFolderStats(id),
  });

  const { data: folderSummaryReport } = useQuery({
    queryKey: ['viewerUserSummaryReport', id],
    queryFn: () => ReportService.getViewerUserSummaryFolderReport(id),
  });

  const { data: videosReport } = useQuery({
    queryKey: ['videosReport', id, sortField, sortOrder],
    queryFn: () => ReportService.getVideosReport({ id, sortField, sortOrder }),
    keepPreviousData: true,
  });

  const showSummaryReportModal = (contentId, contentType, contentTitle) => {
    setCurrentId(contentId);
    setCurrentContentType(contentType);
    setCurrentContentTitle(contentTitle);
    setIsSummaryModalVisible(true);
  };

  return (
    <div className={classes.ViewerReportPage}>
      <ReportHeader
        reportTitle="Viewer Report"
        contentType="folder"
        contentTitle={folderStats?.name}
        folderOwner={folderStats?.folderOwnerName}
        exportReportToPdf={() => exportReportToPdf(id, setIsExporting)}
        exportTableToExcel={() => exportTableToExcel(currentTab)}
        isExport={isExport}
        isExporting={isExporting}
      />
      {!isExport && (
        <ReportTabs
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      )}
      {(currentTab === 'Overview' || isExport) && (
        <FolderStats folderStats={folderStats} />
      )}
      {(currentTab === 'Viewers' || isExport) && (
        <div className={classes.tableContainer}>
          <TabContentTitle title="User Report Summary" />
          <UsersTable report={folderSummaryReport} isExport={isExport} />
        </div>
      )}
      {(currentTab === 'Videos' || isExport) && (
        <VideosReport
          report={videosReport}
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          showSummaryReportModal={showSummaryReportModal}
          isExport={isExport}
        />
      )}
      {!isExport && (
        <SummaryViewerReportModal
          show={isSummaryModalVisible}
          handleClose={() => setIsSummaryModalVisible(false)}
          id={currentId}
          contentType={currentContentType}
          contentTitle={currentContentTitle}
        />
      )}
    </div>
  );
}
