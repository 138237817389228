import React from 'react';

import classes from './styles.module.scss';

export default function Stat({ title, value, description, width, details }) {
  return (
    <div className={classes.Stat} style={{ flex: width }}>
      <h4 className={classes.title}>{title}</h4>
      <p className={classes.value}>{value}</p>
      <p className={classes.description}>{description}</p>
      {details && (
        <ul className={classes.details}>
          {details.map((detail) => (
            <li key={detail}>{detail}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
