import React from 'react';

import InfoButton from '../InfoButton';

import classes from './styles.module.scss';
import folderIcon from '../../../../assets/images/my-library/folder2.svg';
import subfolderIcon from '../../../../assets/images/my-library/subfolder2.svg';

export default function FolderRow({
  row,
  nestLevel,
  showSummaryReportModal,
  isExport,
}) {
  const isSubfolder = !!row.parentId;

  return (
    <tr className={classes.FolderRow}>
      <td>
        <div
          style={{
            position: 'relative',
            left: `${(nestLevel - 1) * 40}px`,
          }}
        >
          <img src={isSubfolder ? subfolderIcon : folderIcon} alt="" />
          <span className={classes.name}>{row.name}</span>
        </div>
      </td>
      <td>{row.meetingCount}</td>
      <td />
      <td />
      <td />
      {!isExport && (
        <td>
          {row.meetingCount > 0 && (
            <InfoButton
              onClick={() =>
                showSummaryReportModal(
                  row.id,
                  isSubfolder ? 'subfolder' : 'folder',
                  row.name
                )
              }
            />
          )}
        </td>
      )}
    </tr>
  );
}
