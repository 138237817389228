import folderIcon from '../../../assets/images/my-library/folder2.svg';
import subfolderIcon from '../../../assets/images/my-library/subfolder2.svg';
import videoIcon from '../../../assets/images/my-library/video2.svg';

export const getContentTypeIcon = (contentType) => {
  switch (contentType) {
    case 'folder':
      return folderIcon;
    case 'subfolder':
      return subfolderIcon;
    case 'meeting':
      return videoIcon;
    default:
      return '';
  }
};
