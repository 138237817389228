import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';

import Dropdown from './Dropdown';
import Checkbox from '../../components/Checkbox';
import Options from './Options';

import classes from './styles.module.scss';
import EmojiPickerWithButton from '../../components/EmojiPickerWithButton';
import ConfirmButton from '../../components/Buttons/ConfirmButton';

export default function CreateQuestionModal({
  show,
  setQuestion,
  fetchedQuestionData,
  setIsQuestionFormVisible,
  setFetchedQuestionData,
  hidePlaceholder,
  updateQuestionSection,
}) {
  const [isAnswerRequired, setIsAnswerRequired] = useState(false);
  const [questionText, setQuestionText] = useState('');
  const [sectionId, setSectionId] = useState('');
  const [questionOption, setQuestionOption] = useState('Single Text Field');
  const [options, setOptions] = useState([]);

  const changeQuestionText = (event) => {
    setQuestionText(event.target.value);
  };

  const submitQuestion = () => {
    if (!questionText.trim()) {
      return;
    }

    const answerOptions =
      questionOption !== 'Single Text Field' && options.length
        ? {
            type: questionOption,
            options: options.map((option) => option.value),
          }
        : null;

    if (sectionId) {
      updateQuestionSection({
        sectionId,
        question: {
          isAnswerRequired,
          text: questionText,
          options: answerOptions,
        },
      });
      setIsQuestionFormVisible(false);

      setTimeout(() => {
        setFetchedQuestionData(null);
      }, 200);
    } else {
      setQuestion({
        isAnswerRequired,
        text: questionText,
        options: answerOptions,
      });
    }
  };

  const cancelAddingQuestion = () => {
    setIsQuestionFormVisible(false);
    hidePlaceholder();
  };

  useEffect(() => {
    if (fetchedQuestionData) {
      setIsAnswerRequired(fetchedQuestionData.isAnswerRequired);
      setQuestionText(fetchedQuestionData.text);
      setSectionId(fetchedQuestionData.sectionId);

      let questionType = 'Single Text Field';

      if (fetchedQuestionData.options) {
        questionType = fetchedQuestionData.options.type;

        setOptions(
          fetchedQuestionData.options.options.map((option) => ({
            key: Math.random(),
            value: option,
          }))
        );
      }

      setQuestionOption(questionType);
    } else {
      setSectionId('');
      setIsAnswerRequired(false);
      setQuestionText('');
      setQuestionOption('Single Text Field');
    }
  }, [fetchedQuestionData]);

  /*   useEffect(() => {
    setFetchedQuestionData(null);
  }, [setFetchedQuestionData]); */

  useEffect(() => {
    if (!fetchedQuestionData || !fetchedQuestionData.options) {
      setOptions([
        {
          value: 'Add option',
          key: Math.random(),
        },
        {
          value: 'Add option',
          key: Math.random(),
        },
      ]);
    }
  }, [questionOption, fetchedQuestionData]);

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.CreateQuestionModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={cancelAddingQuestion}>
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.content}>
        <h3>Insert a question</h3>
        <div className={classes.form}>
          <Dropdown
            questionOption={questionOption}
            setQuestionOption={setQuestionOption}
          />
          <div className={classes.questionContainer}>
            <textarea
              placeholder="Type your question"
              value={questionText}
              onChange={changeQuestionText}
            />
            <EmojiPickerWithButton
              setCommentText={setQuestionText}
              buttonBottom="12px"
              buttonRight="24px"
              pickerTop="52px"
            />
          </div>
          {questionOption !== 'Single Text Field' && (
            <div className={classes.optionsContainer}>
              <Options
                options={options}
                setOptions={setOptions}
                questionOption={questionOption}
              />
            </div>
          )}
        </div>
        <footer className={classes.footer}>
          <Checkbox
            isChecked={isAnswerRequired}
            onClick={() => setIsAnswerRequired((prevState) => !prevState)}
            label="Responses to this question are required"
            labelStyle={{
              fontSize: '24px',
              linHeight: '100%',
              color: 'rgba(0, 0, 0, 0.8)',
            }}
          />
          <ConfirmButton
            onClick={submitQuestion}
            isDisabled={!questionText.trim()}
          />
        </footer>
      </div>
    </Modal>
  );
}
