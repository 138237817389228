import React, { useState, useEffect } from 'react';

import classes from './styles.module.scss';

export default function FontSize({ onChange, currentState }) {
  const [fontSize, setFontSize] = useState(16);

  useEffect(() => {
    onChange(fontSize);
  }, [fontSize, onChange]);

  const changeFontSize = (event) => {
    let size = event.target.value.replace(/\D/g, '');

    if (size < 1) {
      size = 1;
    } else if (size > 72) {
      size = 72;
    }

    setFontSize(+size);
  };

  const incrementFontSize = () => {
    setFontSize((prevSize) => {
      if (prevSize === 72) {
        return prevSize;
      }

      return prevSize + 1;
    });
  };

  const decrementFontSize = () => {
    setFontSize((prevSize) => {
      if (prevSize === 1) {
        return prevSize;
      }

      return prevSize - 1;
    });
  };

  useEffect(() => {
    if (!currentState?.fontSize || currentState?.fontSize !== fontSize) {
      onChange(fontSize);
    }
  }, [currentState?.fontSize, fontSize, onChange]);

  return (
    <div className={classes.FontSize}>
      <button type="button" onClick={decrementFontSize}>
        <svg
          width="20"
          height="4"
          viewBox="0 0 20 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.666504 1.99935C0.666504 1.26297 1.26346 0.666016 1.99984 0.666016H17.9998C18.7362 0.666016 19.3332 1.26297 19.3332 1.99935C19.3332 2.73573 18.7362 3.33268 17.9998 3.33268H1.99984C1.26346 3.33268 0.666504 2.73573 0.666504 1.99935Z"
            fill="black"
          />
        </svg>
      </button>
      <input
        className={classes.fontSize}
        value={fontSize}
        pattern="^[0-9]*$"
        onChange={changeFontSize}
      />
      <button type="button" onClick={incrementFontSize}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.3332 1.99935C11.3332 1.26294 10.7368 0.666016 9.99984 0.666016C9.26286 0.666016 8.6665 1.26294 8.6665 1.99935V8.66602H1.99984C1.26286 8.66602 0.666504 9.26294 0.666504 9.99935C0.666504 10.7358 1.26286 11.3327 1.99984 11.3327H8.6665V17.9993C8.6665 18.7358 9.26286 19.3327 9.99984 19.3327C10.7368 19.3327 11.3332 18.7358 11.3332 17.9993V11.3327H17.9998C18.7368 11.3327 19.3332 10.7358 19.3332 9.99935C19.3332 9.26294 18.7368 8.66602 17.9998 8.66602H11.3332V1.99935Z"
            fill="black"
          />
        </svg>
      </button>
    </div>
  );
}
