/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import Pagination from 'react-js-pagination';

import moment from 'moment';

import Th from '../Th';
import SummaryViewerReportModal from '../../modals/SummaryViewerReportModal';
import Header from '../Header';
import Navigation from '../Navigation';
import Breadcrumbs from '../Breadcrumbs';
import SearchBar from '../SearchBar';
import FilledButton from '../Buttons/FilledButton';
import CreateFolderMenu from './CreateFolderMenu';
import FolderRow from './FolderRow';
import MeetingRow from './MeetingRow';
import Subfolder from './Subfolder';
import MeetingDetailsModal from '../../modals/MeetingDetailsModal';
import ShareModal from '../../modals/ShareModal';
import FolderUsersModal from '../../modals/FolderUsersModal';

import copyFolderLink from '../../helpers/copyFolderLink';
import { UiContext } from '../../context/UiContext';
import useConfirmDelete from '../../hooks/useConfirmDelete';
import UserService from '../../services/UserService';
import classes from './styles.module.scss';
import downloadFolder from '../../helpers/downloadFolder';
import { ORG_ROLES } from '../../constants/main';

const ths = [
  {
    field: 'name',
    title: 'Name',
  },
  {
    field: 'ownerName',
    title: 'Owner',
  },
  {
    field: 'updatedAt',
    title: 'Modified Date',
  },
];

export default function Library({
  searchInput,
  setSearchInput,
  isMobile,
  page,
  setPage,
  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  debouncedSearchInput,
  refetchFolders,
  refetchFolder,
  areFoldersFetching,
  isFolderFetching,
  folders,
  openedFolder,
  id,
  isShare,
  itemsRange,
  deleteFolderMutation,
  moveToTrashMeetingMutation,
  updateFolderMutation,
  breadcrumbLabel,
  breadcrumbTo,
  deletedMeetings,
  restoreMeetingMutation,
  deleteMeetingMutation,
  moveFolderToTrashMutation,
  restoreFolderMutation,
}) {
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const [isCreateFolderMenuVisible, setIsCreateFolderMenuVisible] =
    useState(false);
  const [isShareFolderModalVisible, setIsShareFolderModalVisible] =
    useState(false);
  const [isMeetingDetailsModalVisible, setIsMeetingDetailsModal] =
    useState(false);
  const [isFolderUsersModalVisible, setIsFolderUsersModalVisible] =
    useState(false);

  const [
    isSummaryViewerReportModalVisible,
    setIsSummaryViewerReportModalVisible,
  ] = useState(false);
  const [currentReportData, setCurrentReportData] = useState(null);

  const { notifySuccess, savedPageNumber, setSavedPageNumber } =
    useContext(UiContext);
  const navigate = useNavigate();

  const addButtonRef = useRef(null);

  const { data: user } = useQuery('me', UserService.getMe);

  const isFolderOwner = openedFolder?.data?.userRole === 'owner';
  const isFolderContributor = openedFolder?.data?.userRole === 'contributor';
  const isFolderOwnerOrContributor = isFolderOwner || isFolderContributor;

  const isTrashBin = breadcrumbTo === '/trash-bin';

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  useEffect(() => {
    const isValidShareRootFolder =
      isShare === 'true' && id && openedFolder?.data.Parent === null;

    if (isValidShareRootFolder) {
      setIsShareFolderModalVisible(true);
      setSelectedFolder(openedFolder?.data);
    }
  }, [id, isShare, openedFolder?.data]);

  useEffect(() => {
    if (debouncedSearchInput) {
      setPage(1);
    }
  }, [debouncedSearchInput, setPage]);

  useEffect(() => {
    if (savedPageNumber) {
      setPage(savedPageNumber);
    }

    return () => {
      if (id) {
        setSavedPageNumber(null);
      }
    };
  }, [id, savedPageNumber, setPage, setSavedPageNumber]);

  const switchPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleDetailsMeeting = useCallback((meeting) => {
    setSelectedMeeting(meeting);
    setIsMeetingDetailsModal(true);
  }, []);

  const handleMoveFolderToTrash = useConfirmDelete(
    moveFolderToTrashMutation,
    'folder'
  );
  const handleRestoreFolder = (item) => restoreFolderMutation(item.id);
  const handleDeleteFolder = useConfirmDelete(deleteFolderMutation, 'folder');
  const handleMoveMeetingToTrash = useConfirmDelete(
    moveToTrashMeetingMutation,
    'video'
  );
  const handleDeleteMeeting = useConfirmDelete(deleteMeetingMutation, 'video');

  const handleShareFolder = useCallback((folder) => {
    setSelectedFolder(folder);
    setIsShareFolderModalVisible(true);
  }, []);

  const handleManageFolderUsers = useCallback((folder) => {
    setSelectedFolder(folder);
    setIsFolderUsersModalVisible(true);
  }, []);

  const handleUpdateTitle = useCallback(
    (folderId, newTitle) => {
      const trimmedTitle = newTitle.trim();

      if (!trimmedTitle.length) {
        return;
      }

      updateFolderMutation({ folderId, name: trimmedTitle });
    },
    [updateFolderMutation]
  );

  const handleCopyFolderLink = useCallback(
    (folderId, meetingId) => {
      copyFolderLink(folderId, meetingId);
      notifySuccess(`Link copied to clipboard`);
    },
    [notifySuccess]
  );

  const showMeetingReport = useCallback((data) => {
    setCurrentReportData(data);
    setIsSummaryViewerReportModalVisible(true);
  }, []);

  const showFolderReport = (data) => {
    window.open(`/viewer-report/${data.id}`, '_blank');
  };

  const isLoading = areFoldersFetching || isFolderFetching;
  const breadcrumbsOptions = [{ label: breadcrumbLabel, to: breadcrumbTo }];

  let subfoldersContent;
  let tableContent;

  if (folders) {
    tableContent = folders.rows.map((fdr) => (
      <FolderRow
        key={fdr.id}
        onClick={() => {
          if (isTrashBin) {
            return;
          }

          navigate(`${fdr.id}`);
          setSavedPageNumber(page);
        }}
        classes={classes}
        folder={fdr}
        user={user}
        onUpdateTitle={(newTitle) => handleUpdateTitle(fdr.id, newTitle)}
        handleDelete={() => handleDeleteFolder(fdr)}
        handleShare={() => handleShareFolder(fdr)}
        handleMoveToTrash={() => handleMoveFolderToTrash(fdr)}
        handleRestore={() => handleRestoreFolder(fdr)}
        handleManageUsers={() => handleManageFolderUsers(fdr)}
        handleCopyLink={() => handleCopyFolderLink(fdr.id)}
        showReport={showFolderReport}
        isTrashBin={isTrashBin}
      />
    ));

    if (isTrashBin && deletedMeetings?.length) {
      const deletedMeetingsContent = deletedMeetings.map((meeting) => (
        <MeetingRow
          key={meeting.id}
          classes={classes}
          meeting={meeting}
          folderUserRole={openedFolder?.data?.userRole}
          user={user}
          handleDetails={() => handleDetailsMeeting(meeting)}
          handleDelete={() => handleDeleteMeeting(meeting)}
          handleMoveToTrash={() => handleMoveMeetingToTrash(meeting)}
          handleRestore={() => restoreMeetingMutation(meeting.id)}
          handleCopyLink={() =>
            handleCopyFolderLink(meeting.folderId, meeting.id)
          }
          refetchFolder={refetchFolder}
          showReport={showMeetingReport}
          isTrashBin
        />
      ));

      tableContent = [...tableContent, ...deletedMeetingsContent];
    }
  }

  if (openedFolder) {
    const userRole = openedFolder?.data?.userRole;

    subfoldersContent = openedFolder.subfolders.map((subfolder) => {
      const isNotAllowedToDeleteOrShare =
        userRole !== 'owner' && userRole !== 'creator'; /* &&
        subfolder.Owner.id !== user?.id */
      const isNotAllowedToCopyLink = userRole === 'viewer';
      const isTitleEditable =
        userRole === 'owner' ||
        userRole === 'creator' ||
        subfolder.userId === user?.id;

      return (
        <li className={classes.subfolderContainer} key={subfolder.id}>
          <Subfolder
            title={subfolder.name}
            onClick={() => navigate(`${breadcrumbTo}/${subfolder.id}`)}
            isTitleEditable={isTitleEditable}
            onUpdateTitle={(newTitle) =>
              handleUpdateTitle(subfolder.id, newTitle)
            }
            hasActionsMenu
            actionsMenuProps={{
              handleShare: () => handleShareFolder(subfolder),
              handleCopyLink: () => handleCopyFolderLink(subfolder.id),
              handleDownload: () => downloadFolder(subfolder),
              isNotAllowedToDelete: isNotAllowedToDeleteOrShare,
              isNotAllowedToShare: isNotAllowedToDeleteOrShare,
              isNotAllowedToCopyLink,
              isNotAllowedToDownload: user?.orgRole?.access !== ORG_ROLES.OWNER,
              handleDelete: () => handleDeleteFolder(subfolder),
              handleMoveToTrash: () => handleMoveFolderToTrash(subfolder),
              handleRestore: () => handleRestoreFolder(subfolder),
            }}
            lastPublishedDate={moment(subfolder.updatedAt).format(
              'MMM D, YYYY'
            )}
          />
        </li>
      );
    });

    tableContent = openedFolder.meetings.map((meeting) => (
      <MeetingRow
        key={meeting.id}
        classes={classes}
        meeting={meeting}
        folderUserRole={openedFolder?.data?.userRole}
        user={user}
        handleDetails={() => handleDetailsMeeting(meeting)}
        handleDelete={() => handleDeleteMeeting(meeting)}
        handleMoveToTrash={() => handleMoveMeetingToTrash(meeting)}
        handleCopyLink={() =>
          handleCopyFolderLink(meeting.folderId, meeting.id)
        }
        refetchFolder={refetchFolder}
        showReport={showMeetingReport}
      />
    ));

    const formattedBreadcrumbs = openedFolder.parentFolders.map((folder) => ({
      label: folder.name,
      to: `${breadcrumbTo}/${folder.id}`,
    }));

    breadcrumbsOptions.push(...formattedBreadcrumbs);
  }

  let isEmpty = id
    ? !openedFolder?.subfolders.length && !openedFolder?.meetings.length
    : !folders?.rows.length;

  if (isTrashBin) {
    isEmpty = !!(!deletedMeetings?.length && !folders?.rows.length);
  }

  return (
    <div className={classes.Library}>
      <Header
        hasUserBlock
        hasSearch={isMobile}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
      <Navigation />
      <div className={classes.container}>
        <div className={classes.libraryHeader}>
          <div>
            <Breadcrumbs breadcrumbsOptions={breadcrumbsOptions} />
            {openedFolder && <h2>{openedFolder.data.name}</h2>}
            {isTrashBin && (
              <span className={classes.warning}>
                All files and folders permanently deleted after 30 days
              </span>
            )}
          </div>

          <div className={classes.buttonsContainer}>
            <SearchBar
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
            {(user?.orgRole?.access !== ORG_ROLES.VIEWER ||
              isFolderOwnerOrContributor) &&
              !isTrashBin && (
                <div ref={addButtonRef}>
                  <FilledButton
                    onClick={() =>
                      setIsCreateFolderMenuVisible((prevState) => !prevState)
                    }
                  >
                    + Add new
                  </FilledButton>
                </div>
              )}
            {isCreateFolderMenuVisible && (
              <CreateFolderMenu
                setIsVisible={setIsCreateFolderMenuVisible}
                refetchFolders={refetchFolders}
                refetchFolder={refetchFolder}
                addButtonRef={addButtonRef}
                folder={openedFolder?.data}
                user={user}
              />
            )}
          </div>
        </div>
        {!!subfoldersContent?.length && (
          <ul className={classes.subfolders}>{subfoldersContent}</ul>
        )}
        {!!tableContent?.length && (
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              <thead>
                <tr>
                  {ths.map((th) => (
                    <Th
                      key={th.field}
                      classes={classes}
                      sortField={sortField}
                      sortOrder={sortOrder}
                      setSortField={setSortField}
                      setSortOrder={setSortOrder}
                      field={th.field}
                      searchInput={searchInput}
                      setSearchInput={setSearchInput}
                      title={th.title}
                    />
                  ))}
                </tr>
              </thead>
              <tbody>{tableContent}</tbody>
            </table>
          </div>
        )}

        {!isLoading && isEmpty && (
          <p className={classes.empty}>This folder is empty</p>
        )}

        {!id && folders?.count > itemsRange && (
          <div className={classes.paginationContainer}>
            <Pagination
              itemClass={classes.button}
              innerClass={classes.buttonsList}
              activeClass={classes.active}
              disabledClass={classes.disabled}
              activePage={page}
              itemsCountPerPage={itemsRange}
              totalItemsCount={folders?.count}
              pageRangeDisplayed={itemsRange}
              prevPageText="Prev"
              nextPageText="Next"
              onChange={switchPage}
            />
          </div>
        )}
      </div>

      <MeetingDetailsModal
        meeting={selectedMeeting}
        show={isMeetingDetailsModalVisible}
        handleClose={() => setIsMeetingDetailsModal(false)}
      />
      <ShareModal
        type={selectedFolder?.parentId ? 'subfolder' : 'folder'}
        data={selectedFolder}
        show={isShareFolderModalVisible}
        handleClose={() => setIsShareFolderModalVisible(false)}
      />
      <FolderUsersModal
        folder={selectedFolder}
        show={isFolderUsersModalVisible}
        handleClose={() => setIsFolderUsersModalVisible(false)}
        handleShare={handleShareFolder}
        user={user}
        refetchFolders={refetchFolders}
      />
      <SummaryViewerReportModal
        show={isSummaryViewerReportModalVisible}
        handleClose={() => setIsSummaryViewerReportModalVisible(false)}
        contentType="meeting"
        contentTitle={currentReportData?.name || currentReportData?.title}
        id={currentReportData?.id}
      />
    </div>
  );
}
