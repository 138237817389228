/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';
import moment from 'moment';
import cn from 'classnames';

import playIcon from '../../assets/images/my-meetings/play.svg';
import videoIcon from '../../assets/images/my-library/video.svg';
import deleteIcon from '../../assets/images/delete.svg';

import SummaryViewerReportModal from '../../modals/SummaryViewerReportModal';
import Th from '../Th';

import { UiContext } from '../../context/UiContext';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import MeetingService from '../../services/MeetingService';
import UserService from '../../services/UserService';
import formatDuration from '../../helpers/formatDuration';
import copyFolderLink from '../../helpers/copyFolderLink';
import downloadVideo from '../../helpers/downloadVideo';
import TitleWithProgress from './TitleWithProgress';
import MeetingDetailsModal from '../../modals/MeetingDetailsModal';
import EditableTitle from '../Library/EditableTitle';
import ActionsMenu from '../Library/ActionsMenu';
import useMeetingProcessing from '../../hooks/useMeetingProcessing';
import { MEETING_TABS, ORG_ROLES, USER_ROLES } from '../../constants/main';

const rowHeight = 70;
const restHeight = 112 + 108 + 44 + 64;
const mobileItemsRange = Math.floor(
  (window.innerHeight - restHeight) / rowHeight
);

const ths = [
  {
    title: 'Name',
    field: 'title',
  },
  {
    title: 'Owner',
    field: 'ownerName',
  },
  {
    title: 'Date Uploaded',
    field: 'createdAt',
  },
  {
    title: '',
    field: 'duration',
  },
  {
    title: '',
    field: 'search',
  },
];

export default function MeetingsList({
  classes,
  searchInput,
  setSearchInput,
  tab,
  destinationMeetingId,
  deleted,
}) {
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [page, setPage] = useState(1);
  // const [processingMeetings, setProcessingMeetings] = useState([]);
  const [debouncedSearchInput] = useDebounce(searchInput, 500);
  const [isMeetingDetailsModalVisible, setIsMeetingDetailsModal] =
    useState(false);
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [isSummaryModalVisible, setIsSummaryModalVisible] = useState(false);
  const [currentContentTitle, setCurrentContentTitle] = useState(null);
  const [currentMeetingId, setCurrentMeetingId] = useState(null);

  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const processing = useMeetingProcessing();

  const { showConfirmActionModal, notifySuccess } = useContext(UiContext);

  const itemsRange = isMobile ? mobileItemsRange : 5;

  const { data: meetings, refetch } = useQuery(
    [
      'meetings',
      page,
      debouncedSearchInput,
      itemsRange,
      tab,
      sortField,
      sortOrder,
      deleted,
    ],
    () =>
      MeetingService.getMeetings({
        page,
        search: debouncedSearchInput,
        limit: itemsRange,
        type: tab,
        sortField,
        sortOrder,
        deleted,
      }),
    {
      keepPreviousData: true,
      //   refetchInterval: processingMeetings.length ? 2500 : 0,
    }
  );

  // Refetch meeting on processing end and notify user
  useEffect(() => {
    if (processing.step === 'finishing') {
      refetch();

      const processedMeeting = meetings.find(
        (meeting) => meeting.id === processing.meetingId
      );
      const isSavedSectionMeeting = processedMeeting?.type === 'saved';

      if (!isSavedSectionMeeting && processedMeeting) {
        showConfirmActionModal({
          message: 'Your video is ready to share.',
          title: '... and done!',
          noButtons: true,
        });
      }
    }
  }, [meetings, processing, refetch, showConfirmActionModal, tab]);

  const { data: user } = useQuery('me', UserService.getMe);

  const { mutate: toggleMeetingTrashStatusMutation } = useMutation(
    MeetingService.toggleMeetingTrashStatus,
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const { mutate: updateMeetingMutation } = useMutation(
    MeetingService.updateMeeting,
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const { mutate: importMeetingMutation } = useMutation(
    MeetingService.importMeeting,
    {
      onSuccess: () => {
        navigate(`/my-videos/${destinationMeetingId}/edit`);
      },
    }
  );

  const switchPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleDetails = useCallback((meeting) => {
    setSelectedMeeting(meeting);
    setIsMeetingDetailsModal(true);
  }, []);

  const handleDelete = useCallback(
    (meetingId) => {
      showConfirmActionModal({
        message: 'Are you sure you want to delete this video?',
        onConfirm: () => toggleMeetingTrashStatusMutation(meetingId),
      });
    },
    [showConfirmActionModal, toggleMeetingTrashStatusMutation]
  );

  const handleCopyFolderLink = useCallback(
    async (folderId, meetingId) => {
      try {
        if (folderId) {
          copyFolderLink(folderId, meetingId);
        } else {
          const { link } = await MeetingService.shareMeetingLink({
            meetingId,
            security: 'public',
          });

          navigator.clipboard.writeText(link);
        }

        notifySuccess(`Link copied to clipboard`);
      } catch (error) {
        console.log(error);
      }
    },
    [notifySuccess]
  );

  const handleUpdateMeeting = useCallback(
    (meetingId, title) => {
      updateMeetingMutation({
        meetingId,
        title,
      });
    },
    [updateMeetingMutation]
  );

  const handleMeetingClick = useCallback(
    (meetingId) => {
      if (!destinationMeetingId) {
        return;
      }

      importMeetingMutation({
        sourceMeetingId: meetingId,
        destinationMeetingId,
      });
    },
    [destinationMeetingId, importMeetingMutation]
  );

  const handlePlay = useCallback(
    (meetingId) => {
      navigate(`/my-videos/${meetingId}`);
    },
    [navigate]
  );

  useEffect(() => {
    setPage(1);
    refetch();
  }, [debouncedSearchInput, refetch]);

  useEffect(() => {
    if (meetings?.rows) {
      if (meetings.rows.length === 0 && page !== 1) {
        setPage((prevPage) => prevPage - 1);
      }
    }
  }, [meetings?.rows, page]);

  const isOrganizationOwner = user?.orgRole?.access === ORG_ROLES.OWNER;

  return (
    <>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            <tr>
              {ths.map((th) => (
                <Th
                  key={th.field}
                  classes={classes}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  setSortField={setSortField}
                  setSortOrder={setSortOrder}
                  field={th.field}
                  title={th.title}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                />
              ))}
            </tr>
          </thead>
          {meetings?.length ? (
            <tbody>
              {meetings.map((meeting) => {
                const isMeetingPublished = meeting.status === 'published';

                const isMeetingExpired =
                  meeting.expiresAt && new Date(meeting.expiresAt) < new Date();

                const isNotAllowedToDownload =
                  !isMeetingPublished ||
                  (user?.role !== USER_ROLES.SUPER_ADMIN &&
                    !isOrganizationOwner);

                const isNotAllowedToView =
                  !isMeetingPublished ||
                  (isMeetingExpired && meeting.userId !== user?.id);

                const isNotAllowedToEdit =
                  (meeting.userId !== user?.id &&
                    meeting.userRole !== 'contributor' &&
                    meeting.userRole !== 'contributor-closed' &&
                    !isOrganizationOwner) ||
                  meeting.status === 'in progress' ||
                  (tab !== MEETING_TABS.MY_VIDEOS &&
                    tab !== MEETING_TABS.MOBILE_UPLOADS);

                const isNotAllowedToShare =
                  meeting.userId !== user?.id || !isMeetingPublished;

                const isNotAllowedToCopyLink =
                  meeting.userId !== user?.id || !isMeetingPublished;

                const isNotAllowedToGetViewerReport =
                  !isMeetingPublished || meeting.userRole === 'viewer';

                const duration =
                  meeting.duration && formatDuration(meeting.duration);

                const lastPublishedDate = isMeetingPublished
                  ? moment(meeting.updatedAt).format('MMM D, YYYY')
                  : 'Not Published';

                const uploadedDate = moment(meeting.createdAt).format(
                  'MMM D, YYYY'
                );

                const title = !isMobile ? (
                  <EditableTitle
                    handleUpdateTitle={(newTitle) =>
                      handleUpdateMeeting(meeting.id, newTitle)
                    }
                    isEditable={!isNotAllowedToEdit}
                  >
                    {meeting.title}
                  </EditableTitle>
                ) : (
                  meeting.title
                );

                const playButton = (
                  <button
                    className={classes.playButton}
                    disabled={isNotAllowedToView}
                    type="button"
                    onClick={(event) => {
                      event.stopPropagation();
                      handlePlay(meeting.id);
                    }}
                  >
                    <img src={playIcon} alt="Play" />
                  </button>
                );

                // console.log(processing);

                return (
                  <tr
                    onClick={() => handleMeetingClick(meeting.id)}
                    key={meeting.id}
                    className={cn({
                      [classes.clickable]: destinationMeetingId,
                    })}
                  >
                    <td className={classes.title}>
                      <div className={classes.left}>
                        <img
                          className={classes.videoIcon}
                          src={videoIcon}
                          alt="Video"
                        />

                        <TitleWithProgress
                          classes={classes}
                          title={title}
                          isMeetingPublished={isMeetingPublished}
                          lastPublishedDate={lastPublishedDate}
                          processing={processing}
                          meetingId={meeting.id}
                        />
                      </div>

                      <div className={classes.right}>
                        {playButton}
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDelete(meeting.id);
                          }}
                          type="button"
                        >
                          <img src={deleteIcon} alt="Delete" />
                        </button>
                      </div>
                    </td>
                    <td className={classes.owner}>
                      {meeting.userId === user?.id
                        ? 'Me'
                        : meeting.ownerName || meeting.Owner?.name}
                    </td>
                    <td className={classes.status}>{uploadedDate}</td>
                    <td className={classes.duration}>{duration}</td>
                    <td className={classes.buttons}>
                      <div className={classes.buttonsContainer}>
                        <div className={classes.actionsContainer}>
                          <ActionsMenu
                            data={meeting}
                            handleDetails={() => handleDetails(meeting)}
                            handleMoveToTrash={() => handleDelete(meeting.id)}
                            handleEdit={() =>
                              navigate(`/my-videos/${meeting.id}/edit`)
                            }
                            handleShare={() =>
                              navigate(
                                `/my-videos/${meeting.id}/edit?share=true`
                              )
                            }
                            handleDownload={() => downloadVideo(meeting)}
                            handleGetViewerReport={() => {
                              setCurrentContentTitle(meeting.title);
                              setCurrentMeetingId(meeting.id);
                              setIsSummaryModalVisible(true);
                            }}
                            handleCopyLink={() =>
                              handleCopyFolderLink(meeting.folderId, meeting.id)
                            }
                            isNotAllowedToDelete={false}
                            isNotAllowedToEdit={isNotAllowedToEdit}
                            isNotAllowedToShare={isNotAllowedToShare}
                            isNotAllowedToDownload={isNotAllowedToDownload}
                            isNotAllowedToCheckDetails={false}
                            isNotAllowedToGetViewerReport={
                              isNotAllowedToGetViewerReport
                            }
                            isNotAllowedToCopyLink={isNotAllowedToCopyLink}
                          />
                        </div>
                        {isNotAllowedToView ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                This video is not yet published. To preview,
                                click on the three dots to the left and select
                                EDIT and then PREVIEW.
                              </Tooltip>
                            }
                          >
                            {playButton}
                          </OverlayTrigger>
                        ) : (
                          playButton
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <p className={classes.noMeetings}>
              {debouncedSearchInput.length
                ? "Oops! There are no videos you're looking for."
                : 'This folder is empty'}
            </p>
          )}
        </table>
      </div>

      {+meetings?.[0]?.count > itemsRange && (
        <div className={classes.paginationContainer}>
          <Pagination
            itemClass={classes.button}
            innerClass={classes.buttonsList}
            activeClass={classes.active}
            disabledClass={classes.disabled}
            activePage={page}
            itemsCountPerPage={itemsRange}
            totalItemsCount={+meetings?.[0]?.count || 0}
            pageRangeDisplayed={itemsRange}
            prevPageText="Prev"
            nextPageText="Next"
            onChange={switchPage}
          />
        </div>
      )}

      {user?.orgRole?.access !== ORG_ROLES.VIEWER && (
        <button
          onClick={() => {
            navigate('/create-video');
          }}
          className={classes.uploadButton}
          type="button"
        >
          <svg
            width="80"
            height="80"
            viewBox="0 0 140 140"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M99.6719 63.6719H76.3281V40.3281C76.3281 36.8332 73.495 34 70 34C66.505 34 63.6719 36.8332 63.6719 40.3281V63.6719H40.3281C36.8332 63.6719 34 66.505 34 70C34 73.495 36.8332 76.3281 40.3281 76.3281H63.6719V99.6719C63.6719 103.167 66.505 106 70 106C73.495 106 76.3281 103.167 76.3281 99.6719V76.3281H99.6719C103.167 76.3281 106 73.495 106 70C106 66.505 103.167 63.6719 99.6719 63.6719Z"
              fill="#ffffff"
            />
          </svg>
        </button>
      )}

      <MeetingDetailsModal
        meeting={selectedMeeting}
        show={isMeetingDetailsModalVisible}
        handleClose={() => setIsMeetingDetailsModal(false)}
      />
      <SummaryViewerReportModal
        show={isSummaryModalVisible}
        handleClose={() => setIsSummaryModalVisible(false)}
        contentTitle={currentContentTitle}
        contentType="meeting"
        id={currentMeetingId}
      />
    </>
  );
}
