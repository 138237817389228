import ReportService from '../../../services/ReportService';

export const exportReportToPdf = async (id, type, setIsExporting) => {
  try {
    setIsExporting(true);

    const pdf = await ReportService.getReportPdf({ id, type });

    const url = window.URL.createObjectURL(new Blob([pdf]));
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${pdf}`;
    link.setAttribute('download', 'report.pdf'); // Specify the file name
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  } finally {
    setIsExporting(false);
  }
};
