export const createFolderStats = (stats) => {
  if (!stats) {
    return [];
  }

  return [
    {
      title: 'Users',
      value: stats.userCount,
      description: 'Total # of users assigned to parent folder',
      width: 1,
    },
    {
      title: 'Videos',
      value: stats.meetingCount,
      description: 'Total # of videos housed in this folder',
      width: 1,
    },
    {
      title: 'Viewer Completion Status',
      value: `${
        Math.round((stats.fullyViewedUserCount / stats.userCount) * 100) || 0
      }%`,
      description:
        'Total % of users who have watched all videos in parent folder',
      details: [
        `${stats.fullyViewedUserCount} ${
          stats.fullyViewedUserCount === 1 ? 'user has' : 'users have'
        } viewed all videos in folder`,
        `${stats.notFullyViewedUserCount} ${
          stats.notFullyViewedUserCount === 1 ? 'user has' : 'users have'
        } outstanding videos to view`,
      ],
      width: 2,
    },
  ];
};
