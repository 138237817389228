import React, { useState } from 'react';

import ViewReportTh from '../../ViewReportTh';

import classes from './styles.module.scss';
import { sortReport } from './helpers';

const ths = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Videos Viewed', field: 'viewedCount' },
  { title: 'Videos Outstanding', field: 'notViewedCount' },
];

export default function UsersTable({ report, isExport }) {
  const [sortField, setSortField] = useState('firstName');
  const [sortOrder, setSortOrder] = useState('asc');

  const sortedReport = sortReport(report, sortField, sortOrder);

  return (
    <table className={classes.UsersTable} id="users-report-table">
      <thead>
        <tr>
          {ths.map((th) => (
            <ViewReportTh
              key={th.field}
              title={th.title}
              field={th.field}
              sortField={sortField}
              sortOrder={sortOrder}
              setSortField={setSortField}
              setSortOrder={setSortOrder}
              isExport={isExport}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedReport?.map((user) => {
          const {
            firstName,
            lastName,
            totalCount,
            viewedCount,
            id: userReportId,
            notViewedCount,
          } = user;

          return (
            <tr key={userReportId}>
              <td>{firstName}</td>
              <td>{lastName}</td>
              <td>
                {viewedCount} out of {totalCount}
              </td>
              <td>{notViewedCount}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
