/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef, useContext } from 'react';

import classNames from 'classnames';

import noteIcon from '../../../assets/images/section-menu/notes.svg';
import saveIcon from '../../../assets/images/section-menu/star.svg';
import questionSection from '../../../assets/images/section-menu/question-section.png';
import audioPlaceholder from '../../../assets/images/section-menu/audio-placeholder.png';
import SectionService from '../../../services/SectionService';
import { ORG_ROLES, STATIC_URL } from '../../../constants/main';
import UserIcon from './UserIcon';
import { UiContext } from '../../../context/UiContext';

export default function Section({
  refetchSections,
  section,
  index,
  setVideoPlayerSource,
  setCurrentSectionId,
  setIsConfirmSectionRemovalVisible,
  classes,
  setIsQuestionFormVisible,
  setFetchedQuestionData,
  user,
  meeting,
  stopVideoPlayer,
  hideQuestionAndAssignSectionForms,
  hidePlaceholder,
  sectionTitle,
  setSectionTitle,
  createDefaultSectionName,
  isActive,
  setIsSectionSavedModalVisible,
  setIsSectionCreationStopped,
  processingProgress,
  updateSectionTitle,
  updateTextSection,
  createSection,
}) {
  const [newSectionTitle, setNewSectionTitle] = useState('');
  const [shouldUpdateTitle, setShouldUpdateTitle] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const titleInputRef = useRef();
  const timer = useRef();
  const audioSectionContainerRef = useRef();

  const { showMeetingNotesModal } = useContext(UiContext);

  let sectionContent = <video src={STATIC_URL + section?.path} />;

  // Set default title for the new section and focus on input
  useEffect(() => {
    if (section.path === 'placeholder') {
      const defaultTitle = createDefaultSectionName(section);

      setSectionTitle(defaultTitle);

      titleInputRef.current.focus();
    }
  }, [createDefaultSectionName, section, setSectionTitle]);

  useEffect(() => {
    if (section) {
      setNewSectionTitle(section.title);
      setShouldUpdateTitle(false);
    }
  }, [section]);

  if (section.path === 'placeholder') {
    sectionContent = (
      <>
        <span>{section.title}</span>
      </>
    );
  }

  useEffect(() => {
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      if (!shouldUpdateTitle) {
        return;
      }
      updateSectionTitle({ ...section, title: newSectionTitle });
    }, 500);
  }, [newSectionTitle, section, shouldUpdateTitle, updateSectionTitle]);

  if (section.type === 'question') {
    sectionContent = (
      <div
        className={classes.questionSectionContent}
        onClick={() => {
          setFetchedQuestionData(section?.Question);
          setIsQuestionFormVisible(true);
        }}
      >
        <img src={questionSection} alt="question" />
      </div>
    );
  } else if (section.type === 'assigned-section') {
    sectionContent = (
      <div className={classes.assignedSectionContent}>
        <span>Section Assigned</span>
      </div>
    );
  } else if (section.type === 'audio') {
    sectionContent = (
      <div
        className={classes.audioBackgroundContainer}
        ref={audioSectionContainerRef}
      >
        <img src={audioPlaceholder} alt="Audio" />
      </div>
    );
  } else if (section.type === 'note') {
    sectionContent = (
      <div
        className={classes.noteSectionContent}
        onClick={() => {
          if (section) {
            showMeetingNotesModal({
              refetchSections,
              noteSectionCount: 0,
              noteSectionData: section,
              createSection,
              updateTextSection,
            });
          }
        }}
      >
        <img src={noteIcon} alt="Note" />
      </div>
    );
  } else if (processingProgress.sectionId === section.id) {
    sectionContent = (
      <div className={classes.processingSectionContent}>
        <div className={classes.progressBarOuter}>
          <div
            className={classes.progressBarInner}
            style={{
              width: `${processingProgress.percentage}%`,
            }}
          />
        </div>
      </div>
    );
  }

  const isAllowedToDelete =
    ((section.userId === user?.id || meeting?.userId === user?.id) &&
      meeting?.userRole !== 'contributor-closed') ||
    section.path === 'placeholder' ||
    user?.orgRole?.access === ORG_ROLES.OWNER;

  const isAllowedToDrag =
    section.userId &&
    (section.userId === user?.id || meeting?.userRole === 'creator') &&
    section.path !== 'placeholder' &&
    meeting?.userRole !== 'contributor-closed';

  const isAllowedToSave =
    section.path !== 'placeholder' && !section.SavedSection;

  const isStarButtonVisible =
    (isHovering && isAllowedToSave) || !!section.SavedSection;

  const isContributed = section.userId !== user?.id && classes.contributed;

  return (
    <li
      style={{
        display:
          meeting?.userRole !== 'creator' && section.type === 'assigned-section'
            ? 'none'
            : '',
      }}
      className={classNames(classes.section, {
        [classes.placeholder]: section.path === 'placeholder',
        [classes.contributed]:
          isContributed || section.type === 'assigned-section',
        [classes.active]: isActive,
      })}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => {
        if (section.path === 'placeholder') {
          return;
        }

        setCurrentSectionId(section?.id);

        if (section.type !== 'question') {
          setIsQuestionFormVisible(false);
        }

        stopVideoPlayer();
        setVideoPlayerSource(section);
      }}
    >
      {isAllowedToDelete && (
        <i
          className={classes.deleteIcon}
          onClick={(event) => {
            event.stopPropagation();

            if (section.path === 'placeholder') {
              stopVideoPlayer();
              hidePlaceholder();
              setIsSectionCreationStopped(true);
              setTimeout(() => setIsSectionCreationStopped(false), 500);
              return;
            }

            hideQuestionAndAssignSectionForms();
            setCurrentSectionId(section?.id);
            setIsConfirmSectionRemovalVisible(true);
          }}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_122_1214)">
              <circle cx="20" cy="18" r="16" fill="white" />
            </g>
            <defs>
              <filter
                id="filter0_d_122_1214"
                x="0"
                y="0"
                width="40"
                height="40"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_122_1214"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_122_1214"
                  result="shape"
                />
              </filter>
            </defs>
            <path
              d="M13.6569 11.6674L9.98874 7.99926L13.6569 4.33114C14.206 3.78196 14.206 2.89158 13.6569 2.3424C13.1077 1.79322 12.2173 1.79322 11.6681 2.3424L8 6.01052L4.33188 2.3424C3.7827 1.79322 2.89232 1.79322 2.34315 2.3424C1.79397 2.89158 1.79397 3.78196 2.34315 4.33114L6.01126 7.99926L2.34315 11.6674C1.79397 12.2166 1.79397 13.1069 2.34315 13.6561C2.89232 14.2053 3.7827 14.2053 4.33188 13.6561L8 9.98799L11.6681 13.6561C12.2173 14.2053 13.1077 14.2053 13.6569 13.6561C14.206 13.1069 14.206 12.2166 13.6569 11.6674Z"
              fill="black"
            />
          </svg>
        </i>
      )}
      {isStarButtonVisible && (
        <i
          className={classes.saveIcon}
          onClick={(event) => {
            if (!isAllowedToSave) {
              return;
            }

            event.stopPropagation();

            SectionService.saveSection(section.id);

            setIsSectionSavedModalVisible(true);
            setTimeout(() => {
              setIsSectionSavedModalVisible(false);
            }, 2000);
          }}
        >
          <img src={saveIcon} alt="Save" />
        </i>
      )}
      <div
        className={classes.sectionContentContainer}
        draggable={isAllowedToDrag}
        onDragStart={(event) =>
          event.dataTransfer.setData('sectionIndex', index)
        }
      >
        {sectionContent}
      </div>
      {section.type === 'assigned-section' && (
        <UserIcon
          classes={classes}
          creatorPhotoPath={section.assigned?.photoPath}
          creatorName={section.assigned?.name || section.assignedEmail}
        />
      )}
      {isContributed && section.path !== 'placeholder' && (
        <UserIcon
          classes={classes}
          creatorPhotoPath={section.creator?.photoPath}
          creatorName={section.creator?.name}
        />
      )}
      <div className={classes.title}>
        {section.path === 'placeholder' ? (
          <input
            ref={titleInputRef}
            type="text"
            value={sectionTitle}
            onChange={(event) => setSectionTitle(event.target.value)}
          />
        ) : (
          <input
            ref={titleInputRef}
            type="text"
            value={newSectionTitle}
            onChange={(event) => {
              setShouldUpdateTitle(true);
              setNewSectionTitle(event.target.value);
            }}
          />
        )}
      </div>
    </li>
  );
}
