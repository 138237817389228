import React, { useState, useEffect } from 'react';

import Progress from '../../Library/MeetingRow/Progress';

export default function TitleWithProgress({
  classes,
  title,
  isMeetingPublished,
  lastPublishedDate,
  processing,
  meetingId,
}) {
  const [processingProgress, setProcessingProgress] = useState(null);

  // Set processing progress, filter by meeting id
  useEffect(() => {
    if (processing.meetingId === meetingId) {
      setProcessingProgress(processing);
    }
  }, [meetingId, processing]);

  return (
    <div className={classes.titleContainer}>
      <span className={classes.titleText}>{title}</span>
      {isMeetingPublished ? (
        <span className={classes.publishedDate}>
          published {lastPublishedDate}
        </span>
      ) : (
        <Progress
          isVisible={processingProgress}
          percentage={processingProgress?.percentage}
          step={processingProgress?.step}
        />
      )}
    </div>
  );
}
