/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import threeDots from '../../../assets/images/my-library/threeDots.svg';
import infoIcon from '../../../assets/images/my-meetings/info.svg';
import shareIcon from '../../../assets/images/my-meetings/share.svg';
import editIcon from '../../../assets/images/my-meetings/edit.svg';
import deleteIcon from '../../../assets/images/delete.svg';
import downloadIcon from '../../../assets/images/my-meetings/download.svg';
import usersIcon from '../../../assets/images/my-meetings/users.svg';
import linkIcon from '../../../assets/images/my-meetings/link.svg';
import reportIcon from '../../../assets/images/my-meetings/report.svg';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

const ActionsMenu = ({
  handleDetails,
  handleDelete,
  handleEdit,
  handleShare,
  handleManageUsers,
  handleDownload,
  handleCopyLink,
  handleGetViewerReport,
  handleMoveToTrash,
  handleRestore,
  isNotAllowedToDelete = true,
  isNotAllowedToEdit = true,
  isNotAllowedToShare = true,
  isNotAllowedToDownload = true,
  isNotAllowedToCheckDetails = true,
  isNotAllowedToManageUsers = true,
  isNotAllowedToCopyLink = true,
  isNotAllowedToGetViewerReport = true,
  isTrashBin,
}) => {
  const [isActionsMenuVisible, setIsActionsMenuVisible] = useState(false);

  const menuRef = useRef(null);
  const actionsRef = useRef(null);

  useOnClickOutside(menuRef, () => setIsActionsMenuVisible(false), actionsRef);

  const options = useMemo(
    () => [
      {
        label: 'Share',
        onClick: () => handleShare(),
        icon: <img src={shareIcon} alt="Share" />,
        disabled: isNotAllowedToShare,
      },
      {
        label: 'Edit',
        onClick: () => handleEdit(),
        icon: <img src={editIcon} alt="Edit" />,
        disabled: isNotAllowedToEdit,
      },
      {
        label: 'Users',
        onClick: () => handleManageUsers(),
        icon: <img src={usersIcon} alt="Users" />,
        disabled: isNotAllowedToManageUsers,
      },
      {
        label: 'Details',
        onClick: () => handleDetails(),
        icon: <img src={infoIcon} alt="Details" />,
        disabled: isNotAllowedToCheckDetails,
      },
      {
        label: 'Move to trash',
        onClick: () => handleMoveToTrash(),
        icon: <img src={deleteIcon} alt="Delete" />,
        disabled: isNotAllowedToDelete,
      },
      {
        label: 'Copy link',
        onClick: () => handleCopyLink(),
        icon: <img src={linkIcon} alt="Copy link" />,
        disabled: isNotAllowedToCopyLink,
      },
      {
        label: 'Viewer Report',
        onClick: () => handleGetViewerReport(),
        icon: <img src={reportIcon} alt="Viewer Report" />,
        disabled: isNotAllowedToGetViewerReport,
      },
      {
        label: 'Download',
        onClick: () => handleDownload(),
        icon: <img src={downloadIcon} alt="Download" />,
        disabled: isNotAllowedToDownload,
      },
    ],
    [
      isNotAllowedToShare,
      isNotAllowedToEdit,
      isNotAllowedToManageUsers,
      isNotAllowedToCheckDetails,
      isNotAllowedToDelete,
      isNotAllowedToCopyLink,
      isNotAllowedToGetViewerReport,
      isNotAllowedToDownload,
      handleShare,
      handleEdit,
      handleManageUsers,
      handleDetails,
      handleMoveToTrash,
      handleCopyLink,
      handleGetViewerReport,
      handleDownload,
    ]
  );

  const trashBinOptions = useMemo(() => {
    return [
      {
        label: 'Restore',
        onClick: () => handleRestore(),
        icon: <img src={linkIcon} alt="Copy link" />,
      },
      {
        label: 'Delete permanently',
        onClick: () => handleDelete(),
        icon: <img src={deleteIcon} alt="Delete" />,
      },
    ];
  }, [handleDelete, handleRestore]);

  const currentOptions = isTrashBin ? trashBinOptions : options;

  return (
    <div ref={actionsRef} className={classes.ActionsMenu}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsActionsMenuVisible((prevState) => !prevState);
        }}
        type="button"
      >
        <img src={threeDots} alt="Info" />
      </button>

      <div
        ref={menuRef}
        className={classNames(classes.menu, {
          [classes.isVisible]: isActionsMenuVisible,
        })}
      >
        <ul>
          {currentOptions.map((option) => (
            <li key={option.label} onClick={(e) => e.stopPropagation()}>
              <button
                onClick={() => {
                  option.onClick();
                  setIsActionsMenuVisible(false);
                }}
                disabled={option.disabled}
                type="button"
              >
                {option.icon}
                {option.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ActionsMenu;
