import React from 'react';

import TabContentTitle from '../TabContentTitle';
import ViewReportTh from '../../ViewReportTh';

import classes from './styles.module.scss';
import { renderRows } from './helpers';

const ths = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Videos in Folder',
    field: 'meetingCount',
  },
  {
    title: 'Viewer Completion Status',
    field: 'completionStatus',
  },
  {
    title: 'Completion %',
    field: 'completionRate',
  },
  {
    title: 'Upload Date',
    field: 'createdAt',
  },
  {
    title: '',
    field: 'actions',
  },
];

export default function VideosReport({
  report,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  showSummaryReportModal,
  isExport,
}) {
  let curretnThs = ths;

  if (isExport) {
    curretnThs = ths.slice(0, ths.length - 1);
  }

  return (
    <div className={classes.VideosReport}>
      <TabContentTitle title="Video Report Summary" />
      <table id="videos-report-table">
        <thead>
          <tr>
            {curretnThs.map((th) => (
              <ViewReportTh
                key={th.field}
                title={th.title}
                field={th.field}
                sortField={sortField}
                sortOrder={sortOrder}
                setSortField={setSortField}
                setSortOrder={setSortOrder}
                isExport={isExport}
              />
            ))}
          </tr>
        </thead>
        <tbody>{renderRows(report, 0, showSummaryReportModal, isExport)}</tbody>
      </table>
    </div>
  );
}
