import React from 'react';

import classes from './styles.module.scss';

export default function InfoButto({ onClick }) {
  return (
    <button className={classes.InfoButton} type="button" onClick={onClick}>
      i
    </button>
  );
}
